module.exports = {
  background: 'rgb(255, 197, 0)', // nav bar text
  backgroundDark: 'rgb(15, 53, 75)', // triangle

  socialLinks: '#000000', //Links on the top page
  primary: 'rgb(0, 153, 0)', //Title Text
  primaryLight: '#000000', //underline
  primaryDark: 'rgb(17, 35, 46)', // Triangle header scroll

  secondary: 'rgb(15, 53, 75)', // Left Triangle
  secondaryLight: 'rgb(15, 53, 75)', //Mid page triangle
  secondaryDark: 'rgb(255, 197, 0)', // Sub Headers
};

// 'rgb(132, 255, 129)' gold
// 'rgb(255, 197, 0)' orange
// rgb'(0, 107, 152)' blue
// 'rgb(0, 153, 0)' green
// 'rgb(17, 35, 36)' green
// 'rgb(124, 255, 255)' green
// #6fffff light blue white
